.rc-rate {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 18px;
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  font-style: normal;
  outline: none;
  
  &-rtl {
    direction: rtl;
  }

  &-disabled &-star {
    cursor: default;
    &:before,
    &-content:before {
      cursor: default;
    }
    &:hover {
      transform: scale(1);
    }
  }

  &-star {
    margin: 0;
    padding: 0;
    display: inline-block;
    margin-right: 8px;
    position: relative;
    transition: all .3s;
    color: #e9e9e9;
    cursor: pointer;
    line-height: 1.5;

    .rc-rate-rtl & {
      margin-right: 0;
      margin-left: 8px;
      float: right;
    }

    &-first,
    &-second {
      transition: all .3s;
    }

    &-focused, &:hover {
      transform: scale(1.1);
    }

    &-first {
      position: absolute;
      left: 0;
      top: 0;
      width: 50%;
      height: 100%;
      overflow: hidden;
      opacity: 0;

      .rc-rate-rtl & {
        right: 0;
        left: auto;
      }
    }

    &-half &-first,
    &-half &-second {
      opacity: 1;
    }

    &-half &-first,
    &-full &-second {
      color: #FFC107;
    }

    &-half:hover &-first,
    &-full:hover &-second {
      color: #FFC107;
    }
  }
}

// .anticon {
//   font-style: normal;
//   vertical-align: baseline;
//   text-align: center;
//   text-transform: none;
//   line-height: 1;
//   text-rendering: optimizeLegibility;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   &:before {
//     display: block;
//     font-family: "anticon" !important;
//   }
// }

// .anticon-star:before { content: "\e660"; };