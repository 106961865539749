.swiper-con {
  position: relative;

  @media screen and (max-width: 575px) {
    margin-bottom: 30px;
  }

  img {
    width: 100%;
  }

  .swiper {
    z-index: 0;

    .swiper-button-prev {
      &:after {
        background: rgba(0, 0, 0, 0.4);
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 18px;
        color: #fff;
      }
    };

    .swiper-button-next {
      &:after {
        background: rgba(0, 0, 0, 0.4);
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 18px;
        color: #fff;
      }
    }

    .swiper-wrapper {
      margin-bottom: 10px;

      .swiper-slide {

        &.swiper-slide-visible {
          img {
            border: 1px solid #EDEDED;
            height: 75px;
          }

        }
      }
    }
  }
}