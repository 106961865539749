.product-card-com {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: 0px !important;
  transition: transform .5s;
  cursor: pointer;

  .img_wrapper {
    display: flex;
    overflow: hidden;

    img {
      width: 100%;
      transition: transform .5s;
      transform: scale(1);
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;

    .name_txt {
      margin-bottom: 10px;
    }

    .price_con {
      margin-bottom: 15px;
    }

    .star_con {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }

  &:hover {
    box-shadow: 0px 5px 25px #0000001A;
    transform: translateY(-5px);

    img {
      transform: scale(1.01);
    }
  }
}

.comment_item_con {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 1px solid #EEEEEE;
  padding-top: 20px;
  padding-bottom: 20px;

  &:last-child {
    border-bottom: 0;
  }

  &:first-child {
    border-top: 1px solid #EEEEEE;
  }

  .icon_wrap {
    svg {
      font-size: 30px;
      color: #1585B4;
    }

    margin-right: 10px;
  }
}

.filter_card_con {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  .header_wrap {
    border-bottom: 1px solid #0000001A;
    margin-bottom: 10px;
    padding-bottom: 10px;
    position: relative;

    .tip {
      position: absolute;
      bottom: 0;
      width: 40px;
      border: 1px solid #1585B4
    }

    &.check_filter {
      margin-bottom: 15px;
    }
  }

  ;

  .check-item-wrap {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .filter-item-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;

    .first-input {
      margin-right: 3px;
    }

    button {
      min-width: auto;
      min-height: auto;
      border-radius: 3px;
      margin-left: 5px;
      padding: 3px;

      div {
        padding: 5px 10px;
        margin-right: 0;

        svg{
          color: #fff;
        }
      }
    }
  }

  ;

  .collapse-con {
    .collapse-item {
      border-bottom: 1px solid #0000001A;
      margin-bottom: 10px;
      padding-bottom: 10px;

      .collapse-content {
        padding-left: 10px;
      }

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }

  ;
}