.empty_address_item{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 50px;

  svg{
    margin-bottom: 10px;
  }
}